.navbar-brand{
  height: 75px;
}

.brand-logo{
  max-height: 100%;
  padding-left: 50px;
}

.navbar-nav li a {
  line-height: 50px;
  font-size: 18px;
  color: var(--main-color);
}

.navbar-nav li a.active{
  border-bottom: 5px solid var(--secondary-color);
}

.navbar{
  background-color: #ffffff;
}

.icon-bar
{
  background: #000000 !important; /*Whatever colour you want for icon lines*/
}
.navbar-toggle
{
  background:transparent !important; /*Whatever colour you want for background */
  border-color: grey;
}

/**
  Estilos sección login
 */

#login{
  /*padding-top: 150px;*/

  /*background: url("../images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100%;
  width:100%;*/
}

#login .login-box{
  background-color: transparent;
  padding: 30px 20px;
  z-index: 3;
}

#login #video_back{
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  z-index: 1;
  min-height: 100%;
}

#login .video_overlay{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: #000000;
  opacity: 0.3;
}

#login .forgot-text a{
  color: #ffffff;
}

#login .forgot-text a:hover{
  color: #ffffff;
}

#login .logo-login{
  padding-bottom: 15px;
}

#login .input-login, #register .input-login{
  border-radius: 0px;
  padding: 30px 12px 30px 12px;
  font-size: 16px;
}

#login .input-login::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
  color: var(--secondary-color);
}
#login .input-login::-moz-placeholder { /* Firefox 19+ */
  font-size: 16px;
}
#login .input-login:-ms-input-placeholder { /* IE 10+ */
  font-size: 16px;
}
#login .input-login:-moz-placeholder { /* Firefox 18- */
  font-size: 16px;
}

#login .input-login:focus, #register .input-login:focus{
  border-color:#ccc;
  outline: 0;
  box-shadow: none;
  border-left: 5px solid var(--main-color);
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

#login .has-error .form-control, #register .has-error .form-control{
  border-color:#ccc;
  outline: 0;
  box-shadow: none;
  border-left: 5px solid red;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

#login .has-error .help-block, #register .has-error .help-block{
  color: red;
}

input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

#login .login-btns{
  padding-top: 20px;
}

#login .login-btns button{
  margin: 0 20px 0 20px;
}

#login .register-btn{
  background-color: #000;
  color: #ffffff;
}

#login .register-btn:hover{
  background-color: #323232;
}

#updateSiteFooter button, #resetPass button{
  color: #ffffff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  font-weight: bold;
  padding-left: 50px;
  padding-right: 50px;
}

.updateModal {
  text-align: center;
  padding: 0!important;
}

.updateModal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.updateModal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

#login a{
  padding-top: 15px;
  font-size: 16px;
  color: #000;
}

#login a:hover{
  color: #4C4C4C;
  text-decoration: none;
}

#login .login-logos{
  position: absolute;
  bottom: 50px;
  z-index: 1;
  display: inline-flex;
  width: 100%;
  left: 0;
  right: 0;
}

#login .login-logos .logo-bottom{
  max-height: 90px;
}

/**
  Estilos sección about
 */

#about{
  background-color: #ffffff;
}

.home-card{
  border: 1px solid #efefef;
  line-height: 1.6;
  box-shadow: 2px 6px 25px rgba(0, 0, 0, 0.1);
  transition: all .3s ease;
  position: relative;
  min-height: 452px;
  max-height: 452px;
}

.home-card:hover {
  box-shadow: 2px 6px 30px rgba(0, 0, 0, 0.2);
}

.home-card-image {
  height: 200px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.home-card-content{
  padding: 30px 30px 60px;
}

.home-card h1{
  margin: 0;
  line-height: 1.2;
  color: #444444;
}

.home-card p{
  margin: 0 0 30px;
  color: #666666;
  font-size: 18px;
}

.home-card-subtitle{
  margin: 0 0 15px;
  font-weight: 700;
  font-size: 1.25em;
  color: var(--secondary-color);
}

.home-card-subtitle hr{
  border-top: 5px solid var(--secondary-color);
}

/**
  Estilos sección register
 */
#register{
  background-color: #ffffff;
}

#register label.btn-primary{
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--main-color);
  border-color: var(--main-color);
  display: flow-root;
}

#register img{
  width: 75%;
  padding-bottom: 10px;
}

#register input[type="file"] {
  display: none;
}

#register .input-login::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
  color: var(--secondary-color);
}
#register .input-login::-moz-placeholder { /* Firefox 19+ */
  font-size: 16px;
}
#register .input-login:-ms-input-placeholder { /* IE 10+ */
  font-size: 16px;
}
#register .input-login:-moz-placeholder { /* Firefox 18- */
  font-size: 16px;
}

.register-text{
  font-size: 20px;
  padding-bottom: 20px;
}

#login, #about, #register{
  display: table;
  height: 100vh;
  width: 100%;
}

.section-content{
  display: table-cell;
  vertical-align: middle;
}

.about-title, .register-title{
  padding-bottom: 60px;
}
